import { useEffect, useRef, useState } from 'react';
import { Box, Button, Slider, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { useSliderItemsData } from './sliderItemsData';
import {
    SelectedValues,
    SubFiltersMenu,
    updateFilters,
    VehicleData,
} from './AdditionalFiltersMenuComponent';
import { IItemListResponse } from 'srs.sharedcomponents/lib/esm/models/responses/srs.itemListResponse.model';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks';
import {
    loading,
    selectSearchValue,
    selectVehicleApplicationClicked,
    updateisMultiSelectFiltersApplied,
    updateMultiSelectFilters,
} from 'srs.sharedcomponents/lib/esm/redux/slices/productlistSlice';
import { useTranslate } from 'srs.sharedcomponents/lib/esm/hooks/useTranslate';
import { extractFilterData } from './FiltersDataExtraction';
import { useHistory } from 'react-router-dom';
import { getFilteredData } from './MultiSelectDataHandling';

const updateCategoryValues = (
    prevSelectedValues: SelectedValues,
    item: string
): SelectedValues => {
    return Object.keys(prevSelectedValues).reduce((acc, category) => {
        const key = category as keyof SelectedValues;
        acc[key] = prevSelectedValues[key].filter((value) => value !== item);
        return acc;
    }, {} as SelectedValues);
};
const blueColor = 'rgb(14, 22, 100) !important';
export const getColor = (value: boolean) =>
    value ? 'white' : 'rgb(33, 37, 41)';
export const updateSelections = (
    updatedFilterItems: any,
    selectedValues: any,
    setData: Function,
    allMakes: string[],
    allYears: string[],
    allModels: string[],
    allComponents: string[]
) => {
    const selections = getFilteredData(
        updatedFilterItems?.refineCatalogs,
        selectedValues,
        allMakes,
        allYears,
        allModels,
        allComponents
    );
    setData(() => ({
        make: { value: selections.makes },
        model: { value: selections.models },
        year: { value: selections.years },
        component: { value: selections.components },
    }));
};
const MultiSelectList = ({
    totalSearchItems,
    updatedFilterItems,
}: {
    totalSearchItems: IItemListResponse | undefined;
    updatedFilterItems: IItemListResponse | undefined;
}) => {
    const [isPriceRangeSelected, setIsPriceRangeSelected] =
        useState<boolean>(true);
    const [isPriceRangeMoved, setIsPriceRangeMoved] = useState<boolean>(false);
    const [priceRangeValues, setPriceRangeValues] = useState<any>([
        -1, 1000000,
    ]);
    const [isApplyButtonVisible, setIsApplyButtonVisible] = useState(false);
    const history = useHistory();
    const [previousLocation, setPreviousLocation] = useState(history.location);
    const handleNavigationAction = (locationSearch: string) => {
        return new Promise<void>((resolve) => {
            history.push(`/ProductListCatalog?search=`);
            setTimeout(() => {
                history.push(`/ProductListCatalog${locationSearch}`);
                resolve();
            }, 50);
        });
    };
    useEffect(() => {
        const handleLocationChange = async (location: any, action: any) => {
            const { pathname, search } = location;
            const isProductListCatalog = pathname === '/ProductListCatalog';
            const isSameSearch = search === previousLocation.search;
            const isActionPush = action === 'PUSH';
            const isActionPop = action === 'POP';

            if (location !== previousLocation) {
                if (
                    isProductListCatalog &&
                    isSameSearch &&
                    (isActionPop || isActionPush)
                ) {
                    await handleNavigationAction(search);
                }
                setPreviousLocation(location);
            }
        };

        const unregisterLocationListener = history.listen(handleLocationChange);
        return () => {
            unregisterLocationListener();
        };
    }, [history, previousLocation]);

    type searchedValue = {
        make: '';
        year: '';
        model: '';
        component: '';
        supplier: '';
        category: '';
    };

    const [selectedValues, setSelectedValues] = useState<SelectedValues>({
        make: [],
        year: [],
        model: [],
        component: [],
        supplier: [],
        category: [],
    });
    const [searchedValueLabel, setSearchedValueLabel] = useState<searchedValue>(
        {
            make: '',
            year: '',
            model: '',
            component: '',
            supplier: '',
            category: '',
        }
    );
    const [data, setData] = useState<VehicleData>({
        make: {},
        model: {},
        year: {},
        component: {},
    });

    const [previousMinPrice, setPreviousMinPrice] = useState<number | null>(
        totalSearchItems?.minPrice ?? null
    );
    const [previousMaxPrice, setPreviousMaxPrice] = useState<number | null>(
        totalSearchItems?.maxPrice ?? null
    );
    const [callForPricingProducts, setCallForPricingProducts] =
        useState<boolean>(false);
    const recentSearchedValue = useAppSelector(selectSearchValue);

    useEffect(() => {
        if (updatedFilterItems?.minPrice == 0) {
            setCallForPricingProducts(true);
        } else {
            setCallForPricingProducts(false);
        }
    }, [updatedFilterItems]);
    const { minPrice, maxPrice, onSaleProducts, newProducts } =
        useSliderItemsData(
            updatedFilterItems,
            isPriceRangeSelected,
            totalSearchItems,
            setPreviousMaxPrice,
            setPreviousMinPrice,
            previousMaxPrice,
            previousMinPrice
        );
    const [isOnSaleClicked, setIsOnSaleClicked] = useState<boolean>(false);
    const [isOnSaleDisabled, setIsOnSaleDisabled] = useState<boolean>(false);
    const [isNewDisabled, setIsNewDisabled] = useState<boolean>(false);
    const [isNewClicked, setIsNewClicked] = useState<boolean>(false);
    const isVehicleApplicationClicked = useAppSelector(
        selectVehicleApplicationClicked
    );
    const { translate } = useTranslate();
    const [isCallForPricingClicked, setIsCallForPricingClicked] =
        useState<boolean>(false);
    const dispatch = useDispatch();
    const [price, setPrice] = useState<number[]>([
        minPrice ?? -1,
        maxPrice ?? 1000000,
    ]);
    const [finalPrice, setFinalPrice] = useState<number[]>([
        priceRangeValues[0] ?? -1,
        priceRangeValues[1] ?? 1000000,
    ]);
    const handleSaleClick = () => {
        setIsPriceRangeSelected(false);
        setIsOnSaleClicked(!isOnSaleClicked);
    };
    const [isInitialPageLoad, setIsInitialPageLoad] = useState(false);
    const {
        makes,
        allYears,
        allModels,
        allComponents,
        allCategoriesWithChildren,
        allInitialCategoriesWithChildren,
        allSuppliers,
    } = extractFilterData(updatedFilterItems, totalSearchItems);

    const handleNewClick = () => {
        setIsPriceRangeSelected(false);
        setIsNewClicked(!isNewClicked);
    };
    const handleCallForPricingClick = () => {
        setIsPriceRangeSelected(false);
        setIsCallForPricingClicked(!isCallForPricingClicked);
    };
    const isSaleActiveFromProducts =
        onSaleProducts && isOnSaleClicked && !isOnSaleDisabled;
    const isNewActiveFromProducts =
        newProducts && isNewClicked && !isNewDisabled;
    const isCallForPricingProductsActive =
        callForPricingProducts && isCallForPricingClicked;
    const filterTranslations = [
        {
            condition: isSaleActiveFromProducts,
            translationKey: 'On_Sale_FacetText',
        },
        {
            condition: isNewActiveFromProducts,
            translationKey: 'CMSNewText',
        },
        {
            condition: isCallForPricingProductsActive,
            translationKey: 'MissingPrice',
        },
    ];

    const additionalFilters = filterTranslations
        .filter(({ condition }) => condition)
        .map(({ translationKey }) => translate(translationKey));
    const extractDeepestCodes = (category: {
        label: string;
        items: any;
        code: string;
    }): string[] => {
        if (!category.items || category.items.length === 0) {
            return category.code ? [category.code] : [];
        }

        return category.items.flatMap(extractDeepestCodes);
    };

    const extractAllDeepestCodes = (
        categories: {
            label: string;
            items: any[];
            code: string;
        }[]
    ): string[] => {
        return categories.flatMap(extractDeepestCodes);
    };

    const categoryCodes = extractAllDeepestCodes(allCategoriesWithChildren);
    const filterIntersectedValues = (items: any[], reference: string | any[]) =>
        items.filter((item: any) => reference.includes(item));
    const [displayedMin, setDisplayedMin] = useState(
        Math.max(finalPrice[0], minPrice ?? 0)
    );
    const [displayedMax, setDisplayedMax] = useState(
        Math.min(finalPrice[1], maxPrice ?? 1000000)
    );
    const allSelectedItems = [
        ...additionalFilters,
        ...filterIntersectedValues(selectedValues.category, categoryCodes),
        ...filterIntersectedValues(selectedValues.supplier, allSuppliers),
        ...filterIntersectedValues(selectedValues.make, makes),
        ...filterIntersectedValues(selectedValues.year, allYears),
        ...filterIntersectedValues(selectedValues.model, allModels),
        ...filterIntersectedValues(selectedValues.component, allComponents),
    ];
    const previousSelectedValues = useRef(allSelectedItems);
    useEffect(() => {
        if (
            Object.values(selectedValues).reduce(
                (acc, curr) => acc + curr.length,
                0
            ) === 0 &&
            !isCallForPricingClicked &&
            !isOnSaleClicked &&
            !isNewClicked
        ) {
            setIsPriceRangeSelected(true);
        }
    }, [selectedValues]);
    useEffect(() => {
        setIsOnSaleClicked(false);
        setIsNewClicked(false);
        setIsCallForPricingClicked(false);
        setIsOnSaleDisabled(false);
        setIsNewDisabled(false);
        setIsApplyButtonVisible(false);
        setPrice([-1, 1000000]);
        setFinalPrice([-1, 1000000]);
        setIsPriceRangeSelected(true);
        setPriceRangeValues([-1, 1000000]);
        if (recentSearchedValue === 'On Sale') {
            setIsOnSaleClicked(true);
            setIsOnSaleDisabled(true);
        } else if (recentSearchedValue === 'NewProducts') {
            setIsNewClicked(true);
            setIsNewDisabled(true);
        }
        setIsPriceRangeMoved(false);
        setPreviousMinPrice(totalSearchItems?.minPrice ?? null);
        setPreviousMaxPrice(totalSearchItems?.maxPrice ?? null);
        previousSelectedValues.current = [];
        setIsInitialPageLoad(true);
    }, [totalSearchItems, recentSearchedValue]);

    const isLoading: boolean = useAppSelector(loading);
    useEffect(() => {
        if (!isLoading) {
            setIsInitialPageLoad(false);
        }
    }, [totalSearchItems, isLoading]);

    const handleSelectedValueClick = (item: string) => {
        if (item === translate('MissingPrice')) {
            handleCallForPricingClick();
        }
        if (item === translate('CMSNewText')) {
            handleNewClick();
        }
        if (item === translate('On_Sale_FacetText')) {
            handleSaleClick();
        }

        setSelectedValues((prevSelectedValues) => {
            const updatedSelections = updateCategoryValues(
                prevSelectedValues,
                item
            );

            updateSelections(
                updatedFilterItems,
                updatedSelections,
                setData,
                makes,
                allYears,
                allModels,
                allComponents
            );

            return updatedSelections;
        });
    };

    const selectedValuesChanged =
        JSON.stringify(previousSelectedValues.current) !==
        JSON.stringify(allSelectedItems);
    const handleApplyFiltersButton = async () => {
        setIsInitialPageLoad(true);
        setIsPriceRangeSelected(!selectedValuesChanged);
        if (
            Object.values(selectedValues).reduce(
                (acc, curr) => acc + curr.length,
                0
            ) === 0 &&
            !isCallForPricingClicked &&
            !isOnSaleClicked &&
            !isNewClicked
        ) {
            setIsPriceRangeSelected(true);
            setPreviousMaxPrice(totalSearchItems?.maxPrice ?? null);
            setPreviousMinPrice(totalSearchItems?.minPrice ?? null);
        }
        previousSelectedValues.current = allSelectedItems;

        await updateFilters(
            {
                dispatchFilters: dispatch,
                onSale: isOnSaleClicked,
                newProduct: isNewClicked,
                priceRange: finalPrice,
                allCategories: allInitialCategoriesWithChildren,
                newSelectedValues: selectedValues,
                callForPricing: isCallForPricingClicked,
                updatedFilterItems: updatedFilterItems,
                isPriceRangeSelected: isPriceRangeSelected,
                priceRangeValues: priceRangeValues,
                searchedLabel: searchedValueLabel,
            },
            !!isVehicleApplicationClicked
        );
    };

    const [showAllSelectedValues, setShowAllSelectedValues] = useState(false);
    const handleClearAllSelectedValues = () => {
        setSelectedValues({
            make: [],
            year: [],
            model: [],
            component: [],
            supplier: [],
            category: [],
        });
        setData({
            make: {},
            model: {},
            year: {},
            component: {},
        });
        !isOnSaleDisabled && setIsOnSaleClicked(false);
        !isNewDisabled && setIsNewClicked(false);
        setIsCallForPricingClicked(false);
        dispatch(updateMultiSelectFilters(null));
        dispatch(updateisMultiSelectFiltersApplied(false));
        setShowAllSelectedValues(false);
        setPrice([-1, 1000000]);
        setFinalPrice([-1, 1000000]);
        setIsPriceRangeSelected(true);
        setPriceRangeValues([-1, 1000000]);
        setPreviousMaxPrice(totalSearchItems?.maxPrice ?? null);
        setPreviousMinPrice(totalSearchItems?.minPrice ?? null);
        previousSelectedValues.current = [];
        setIsPriceRangeMoved(false);
        setIsInitialPageLoad(true);
    };
    const handleToggleClick = () => {
        setShowAllSelectedValues((prevState) => !prevState);
    };
    const handleResetPriceRange = () => {
        setDisplayedMin(minPrice ?? 0);
        setDisplayedMax(maxPrice ?? 1000000);
        setIsPriceRangeMoved(false);
        setFinalPrice([minPrice ?? -1, maxPrice ?? 1000000]);
        setPrice([minPrice ?? -1, maxPrice ?? 1000000]);
        setIsApplyButtonVisible(true);
    };

    useEffect(() => {
        setDisplayedMin(Math.max(finalPrice[0], minPrice ?? 0));
        setDisplayedMax(Math.min(finalPrice[1], maxPrice ?? 1000000));
    }, [finalPrice, minPrice, maxPrice, recentSearchedValue]);
    const renderItems = (items: any[]) => {
        let itemsToDisplay;
        if (showAllSelectedValues) {
            itemsToDisplay = items;
        } else if (isPriceRangeMoved) {
            itemsToDisplay = items.slice(0, 5);
        } else {
            itemsToDisplay = items.slice(0, 6);
        }
        return (
            <>
                <Box>
                    {isPriceRangeMoved && minPrice != null && maxPrice != null && (
                        <Button
                            className="chip-button"
                            style={{
                                border: '1px solid gray',
                                borderRadius: '4px',
                                padding: '5px 10px',
                                textAlign: 'center',
                                cursor: 'pointer',
                                color: 'white',
                                backgroundColor: '#066FEF',
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '6px',
                                width: 'fit-content',
                            }}
                            onClick={() => handleResetPriceRange()}
                            startIcon={
                                <FontAwesomeIcon
                                    icon={faRemove}
                                    size="xs"
                                    style={{ fontSize: '10px' }}
                                />
                            }
                        >
                            <Typography fontSize={'14px'}>
                                ${displayedMin}
                            </Typography>
                            <Typography variant="body1">-</Typography>
                            <Typography fontSize={'14px'}>
                                ${displayedMax}
                            </Typography>
                        </Button>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '10px',
                            margin: '10px 0',
                            maxHeight: showAllSelectedValues ? '400px' : 'auto',
                            overflowY: showAllSelectedValues
                                ? 'auto'
                                : 'hidden',
                        }}
                    >
                        {itemsToDisplay.map((item, index) => {
                            return (
                                <Button
                                    key={item}
                                    className="chip-button"
                                    style={{
                                        border: '1px solid gray',
                                        borderRadius: '4px',
                                        padding: '5px 10px',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        color: 'white',
                                        backgroundColor: '#066FEF',
                                        textTransform: 'none',
                                        display: 'inline-block',
                                        width: 'auto',
                                    }}
                                    startIcon={
                                        <FontAwesomeIcon
                                            icon={faRemove}
                                            size="xs"
                                            style={{
                                                fontSize: '10px',
                                                display: 'inline-block',
                                            }}
                                        />
                                    }
                                    onClick={() =>
                                        handleSelectedValueClick(item)
                                    }
                                >
                                    {item.includes('_')
                                        ? item.split('_').pop()
                                        : item}
                                </Button>
                            );
                        })}
                    </Box>
                    {items.length + (isPriceRangeMoved ? 1 : 0) > 6 &&
                        !showAllSelectedValues && (
                            <Button
                                style={{
                                    marginTop: 1,
                                    fontSize: '10px',
                                    fontWeight: '900',
                                    color: blueColor,
                                    marginBottom: '5px',
                                }}
                                onClick={handleToggleClick}
                            >
                                {translate(`more_txt`)}
                            </Button>
                        )}

                    {showAllSelectedValues &&
                        items.length + (isPriceRangeMoved ? 1 : 0) > 6 && (
                            <Button
                                style={{
                                    marginTop: 1,
                                    fontSize: '10px',
                                    fontWeight: '900',
                                    color: blueColor,
                                    marginBottom: '5px',
                                }}
                                onClick={handleToggleClick}
                            >
                                {translate(`less_txt`)}
                            </Button>
                        )}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  
                        <Button
                            style={{
                                marginTop: 1,
                                fontSize: '12px',
                                fontWeight: '900',
                                color: blueColor,
                                borderColor: blueColor,
                                border: '1px solid',
                                padding: '5px',
                                textTransform: 'capitalize',
                                borderRadius: 5,
                            }}
                            onClick={handleClearAllSelectedValues}
                            id="selectedFiltersApplySection_clearAllButton"
                        >
                            {translate(`ltl_ClearAllText`)}
                        </Button> 
                        <Button
                            style={{
                                fontSize: '12px',
                                fontWeight: '900',
                                textTransform: 'capitalize',
                                color: 'white',
                                backgroundColor: 'rgb(14, 22, 100)',
                                padding: '5px',
                                borderRadius: 5,
                            }}
                            id="selectedFiltersApplySection_applyButton"
                            onClick={() => {
                                handleApplyFiltersButton();
                                setIsApplyButtonVisible(false);
                            }}
                        >
                            {translate('apply_txt')}
                        </Button>
                  
                </Box>
            </>
        );
    };

    const totalSelectedCount =
        allSelectedItems.length + (isPriceRangeMoved ? 1 : 0);

    return (
        <Box style={{ padding: 2, marginTop: '1rem' }}>
          
                <Box
                    sx={{
                        padding: '5%',
                        border: '1px solid',
                        marginBottom: '10%',
                        borderRadius: '5px',
                    }}
                    id="selectedFiltersBox"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                                           {totalSelectedCount > 0 && (
        <Typography sx={{ color: blueColor }}>
            {translate('selected_filters_txt')}
        </Typography>
    )}
                    </Box>
                    {renderItems(allSelectedItems)}
                </Box>  
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                {onSaleProducts && !isOnSaleDisabled && (
                    <Button
                        style={{
                            width: '33%',
                            borderRadius: 5,
                            marginRight: '10px',
                            textAlign: 'center',
                            border: '1px solid gray',
                            cursor: 'pointer',
                            backgroundColor: isOnSaleClicked
                                ? '#066FEF'
                                : 'white',
                            textTransform: 'none',
                            color: getColor(isOnSaleClicked),
                        }}
                        id="onSaleFilterBtn"
                        onClick={handleSaleClick}
                        startIcon={
                            isOnSaleClicked ? (
                                <FontAwesomeIcon
                                    icon={faRemove}
                                    size="xs"
                                    style={{
                                        fontSize: '10px',
                                        marginLeft: '10px',
                                    }}
                                />
                            ) : null
                        }
                    >
                        {translate(`On_Sale_FacetText`)}
                    </Button>
                )}
                {newProducts && !isNewDisabled && (
                    <Button
                        onClick={() => handleNewClick()}
                        style={{
                            width: '33%',
                            borderRadius: 5,
                            marginRight: '10px',
                            textAlign: 'center',
                            border: '1px solid gray',
                            cursor: 'pointer',
                            backgroundColor: isNewClicked ? '#066FEF' : 'white',
                            textTransform: 'none',
                            color: getColor(isNewClicked),
                        }}
                        id="newFilterBtn"
                        startIcon={
                            isNewClicked ? (
                                <FontAwesomeIcon
                                    icon={faRemove}
                                    size="xs"
                                    style={{
                                        fontSize: '10px',
                                        marginLeft: '5px',
                                    }}
                                />
                            ) : null
                        }
                    >
                        {translate(`CMSNewText`)}
                    </Button>
                )}
                {callForPricingProducts && (
                    <Button
                        onClick={() => handleCallForPricingClick()}
                        style={{
                            width: '70%',
                            borderRadius: 5,
                            textAlign: 'center',
                            border: '1px solid gray',
                            cursor: 'pointer',
                            textTransform: 'none',
                            backgroundColor: isCallForPricingClicked
                                ? '#066FEF'
                                : 'white',
                            color: getColor(isCallForPricingClicked),
                        }}
                        id="callForPricingFilterBtn"
                        startIcon={
                            isCallForPricingClicked ? (
                                <FontAwesomeIcon
                                    icon={faRemove}
                                    size="xs"
                                    style={{
                                        fontSize: '10px',
                                        marginLeft: '10px',
                                    }}
                                />
                            ) : null
                        }
                    >
                        {translate(`MissingPrice`)}
                    </Button>
                )}
            </Box>

            {minPrice != null &&
                maxPrice != null &&
                !isCallForPricingClicked &&
                !isInitialPageLoad && (
                    <Box
                        sx={{
                            backgroundColor: '#F5F5F5',
                            padding: '0px 10px 10px 10px',
                            marginTop: '10px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '6px',
                                padding: ' 4px',
                                fontWeight: 'bold',
                                width: 'fit-content',
                                transition: 'all 0.3s ease-in-out',
                                borderRadius: '4px',
                                color: 'rgb(33, 37, 41)',
                            }}
                        >
                            <Typography id={'priceOption_Price'}>
                                {translate(`ltl_PriceFacetText`)}
                            </Typography>
                        </Box>

                        <Box
                            style={{
                                marginTop: 2,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                                width: 'auto',
                            }}
                        >
                            <Typography
                                style={{ textAlign: 'center', padding: '5px' }}
                            >{`${
                                totalSearchItems?.items[0]?.price
                                    ?.currencySymbol
                                    ? totalSearchItems?.items[0]?.price
                                          ?.currencySymbol
                                    : '$'
                            }${minPrice}`}</Typography>
                            <Slider
                                value={price}
                                onChange={(
                                    event: Event,
                                    newValue: number | number[]
                                ) => {
                                    if (
                                        Array.isArray(newValue) &&
                                        (newValue[0] !== finalPrice[0] ||
                                            newValue[1] !== finalPrice[1])
                                    ) {
                                        setPrice(newValue);
                                    }
                                }}
                                onChangeCommitted={(
                                    event: Event,
                                    newValue: number | number[]
                                ) => {
                                    if (
                                        Array.isArray(newValue) &&
                                        (newValue[0] !== finalPrice[0] ||
                                            newValue[1] !== finalPrice[1])
                                    ) {
                                        setFinalPrice(newValue);
                                        setPriceRangeValues([
                                            minPrice,
                                            maxPrice,
                                        ]);
                                        setIsPriceRangeMoved(true);
                                        setIsApplyButtonVisible(true);
                                    }
                                }}
                                valueLabelDisplay="auto"
                                sx={{
                                    '& .MuiSlider-valueLabel': {
                                        backgroundColor: '#066FEF',
                                        color: 'white',
                                    },
                                }}
                                min={Number(minPrice)}
                                max={Number(maxPrice)}
                                style={{ flex: 1 }}
                                valueLabelFormat={(value: any) => `$${value}`}
                            />
                            <Typography
                                style={{
                                    textAlign: 'center',
                                    marginLeft: '5px',
                                }}
                            >{`${
                                totalSearchItems?.items[0]?.price
                                    ?.currencySymbol
                                    ? totalSearchItems?.items[0]?.price
                                          ?.currencySymbol
                                    : '$'
                            }${maxPrice}`}</Typography>
                        </Box>
                    </Box>
                )}
            <SubFiltersMenu
                totalItems={totalSearchItems}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                updatedFilterItems={updatedFilterItems}
                handleApplyFiltersButton={handleApplyFiltersButton}
                data={data}
                setData={setData}
                setSearchedValueLabel={setSearchedValueLabel}
                selectedValuesChanged={selectedValuesChanged}
                setIsApplyButtonVisible={setIsApplyButtonVisible}
                isApplyButtonVisible={isApplyButtonVisible}
                isInitialPageLoad={isInitialPageLoad}
            />
        </Box>
    );
};

export default MultiSelectList;
