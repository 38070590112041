import {
    faChevronUp,
    faChevronDown,
    faRemove,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
    ActionButton,
    SelectedValues,
    VehicleData,
} from './AdditionalFiltersMenuComponent';
import { useTranslate } from 'srs.sharedcomponents/lib/esm/hooks/useTranslate';

interface CollapsibleSectionProps {
    title: string;
    sectionData: string[];
    expanded: { [key: string]: boolean };
    toggleSection: React.Dispatch<React.SetStateAction<string>>;
    handleClick: (item: string) => void;
    getProductFilterOptionStyles: (
        allData: VehicleData,
        selectedValues: SelectedValues,
        item: string,
        dataObject: Record<string, string[]>
    ) => { backgroundColor: string; isDisabled: boolean; isSelected: boolean };
    showAll: { [key: string]: boolean };
    toggleShowMore: () => void;
    sectionKey: string;
    sectionMappedData: Record<string, string[]>;
    completeMappedFiltersData: VehicleData;
    selectedValues: SelectedValues;
    selectedSectionLength: number;
    handleSelectAllClearAllButtonsForRestSections: any;
    handleResetsectionsSelection: any;
}

const blueColor = 'rgb(14, 22, 100) !important';

export const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
    title,
    sectionData,
    expanded,
    toggleSection,
    handleClick,
    getProductFilterOptionStyles,
    showAll,
    toggleShowMore,
    sectionKey,
    sectionMappedData,
    completeMappedFiltersData,
    selectedValues,
    selectedSectionLength,
    handleSelectAllClearAllButtonsForRestSections,
    handleResetsectionsSelection,
}) => {
    const { translate } = useTranslate();

    const handleToggleSection = () => {
        toggleSection(sectionKey);
    };

    const handleShowMoreClick = () => {
        toggleShowMore();
    };

    const handleItemClick = (item: string) => {
        handleClick(item);
    };

    const enabledItems = sectionData.filter((item) => {
        const { isDisabled } = getProductFilterOptionStyles(
            completeMappedFiltersData,
            selectedValues,
            item,
            sectionMappedData
        );
        return !isDisabled;
    });

    const renderItems = (items: any[], dataDisplayCount?: number) => {
        const itemsToRender = dataDisplayCount
            ? items.slice(0, dataDisplayCount)
            : items;
        return itemsToRender.map((item, index) => {
            const { backgroundColor, isDisabled, isSelected } =
                getProductFilterOptionStyles(
                    completeMappedFiltersData,
                    selectedValues,
                    item,
                    sectionMappedData
                );

            return (
                <Button
                    key={item}
                    id={`${sectionKey.toLowerCase()}FilterBtn_${item.replaceAll(
                        ' ',
                        ''
                    )}`}
                    disabled={isDisabled}
                    className="chip-button"
                    style={{
                        border: '1px solid gray',
                        borderRadius: '4px',
                        padding: '5px 10px',
                        textAlign: 'left',
                        cursor: 'pointer',
                        backgroundColor: backgroundColor,
                        textTransform: 'none',
                        width: sectionKey === 'components' ? '100%' : 'auto',
                        wordBreak: 'break-word',
                        color:
                            backgroundColor === '#066FEF'
                                ? 'white'
                                : 'rgb(33, 37, 41)',
                        justifyContent: 'flex-start',
                    }}
                    onClick={() => handleItemClick(item)}
                    startIcon={
                        isSelected ? (
                            <FontAwesomeIcon
                                icon={faRemove}
                                size="xs"
                                style={{
                                    fontSize: '10px',
                                    display: 'inline-block',
                                }}
                            />
                        ) : null
                    }
                >
                    {item}
                </Button>
            );
        });
    };

    const { selectionState } =
        handleSelectAllClearAllButtonsForRestSections(enabledItems);

    return (
        <Box
            id={`${sectionKey.toLowerCase()}MenuOption_${sectionKey.replaceAll(
                ' ',
                ''
            )}`}
        >
            <Box
                sx={{
                    position: 'relative',
                    borderRadius: '4px',
                  textAlign: 'center',
                    border: '1px solid gray',
                    marginTop: '15px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '5px 10px',
                    cursor: 'pointer',
                    wordBreak: 'break-word',
                    backgroundColor:
                        selectedSectionLength > 0 ? '#066FEF' : 'white',
                    color:
                        selectedSectionLength > 0 ? 'white' : 'rgb(33, 37, 41)',
                }}
                onClick={handleToggleSection}
            >
                {selectedSectionLength > 0 && (
                    <Typography
                        sx={{
                            border: '1px solid',
                            borderRadius: '50%',
                            minWidth: '30px',
                            minHeight: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            fontSize: '12px',
                        }}
                    >
                        {selectedSectionLength}
                    </Typography>
                )}
                <Typography>{title}</Typography>
                <FontAwesomeIcon
                    icon={expanded[sectionKey] ? faChevronUp : faChevronDown}
                />
            </Box>

            {expanded[sectionKey] && (
                <Box
                    sx={{
                        marginTop: '10px',
                      wordBreak: 'break-all',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent:
                                selectionState === 'noneSelected'
                                    ? 'flex-end'
                                    : 'space-between',
                            marginBottom: '10px',
                            gap: '10px',
                            flexWrap: 'wrap',
                        }}
                    >
                        {(() => {
                            if (selectionState === 'no Button') {
                                return null;
                            }
                            if (selectionState === 'allSelected') {
                                return (
                                    <ActionButton
                                        onClick={() =>
                                            handleResetsectionsSelection(
                                                false,
                                                enabledItems
                                            )
                                        }
                                        buttonType="clearAll"
                                        buttonId={`${sectionKey.toLowerCase()}MenuOption_${sectionKey.replaceAll(
                                            ' ',
                                            ''
                                        )}_clearAll`}
                                    >
                                        {translate(`ltl_ClearAllText`)}{' '}
                                    </ActionButton>
                                );
                            } else if (selectionState === 'someSelected') {
                                return (
                                    <>
                                        <ActionButton
                                            onClick={() =>
                                                handleResetsectionsSelection(
                                                    false,
                                                    enabledItems
                                                )
                                            }
                                            buttonType="clearAll"
                                            buttonId={`${sectionKey.toLowerCase()}MenuOption_${sectionKey.replaceAll(
                                                ' ',
                                                ''
                                            )}_clearAll`}
                                        >
                                            {translate(`ltl_ClearAllText`)}{' '}
                                        </ActionButton>
                                        <ActionButton
                                            onClick={() =>
                                                handleResetsectionsSelection(
                                                    true,
                                                    enabledItems
                                                )
                                            }
                                            buttonType="selectAll"
                                            buttonId={`${sectionKey.toLowerCase()}MenuOption_${sectionKey.replaceAll(
                                                ' ',
                                                ''
                                            )}_selectAll`}
                                        >
                                            {translate('selectAll_txt')}
                                        </ActionButton>
                                    </>
                                );
                            } else if (selectionState === 'noneSelected') {
                                return (
                                    <ActionButton
                                        onClick={() =>
                                            handleResetsectionsSelection(
                                                true,
                                                enabledItems
                                            )
                                        }
                                        buttonType="selectAll"
                                        buttonId={`${sectionKey.toLowerCase()}MenuOption_${sectionKey.replaceAll(
                                            ' ',
                                            ''
                                        )}_selectAll`}
                                    >
                                        {translate('selectAll_txt')}
                                    </ActionButton>
                                );
                            }
                        })()}
                    </Box>
                    {showAll[sectionKey] ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '10px',
                                maxHeight: '500px',
                                overflowY: 'auto',
                                marginLeft: '20px',
                                marginRight: '20px',
                            }}
                        >
                            {renderItems(enabledItems)}
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '10px',
                                marginLeft: '20px',
                                marginRight: '20px',
                            }}
                        >
                            {renderItems(enabledItems, 6)}{' '}
                        </Box>
                    )}
                </Box>
            )}

            {enabledItems.length > 6 && expanded[sectionKey] && (
                <Button
                    onClick={handleShowMoreClick}
                    style={{
                        marginTop: 1,
                        fontSize: '10px',
                        fontWeight: '900',
                        color: blueColor,
                    }}
                >
                    {showAll[sectionKey]
                        ? translate(`less_txt`)
                        : translate(`more_txt`)}
                </Button>
            )}
        </Box>
    );
};
