/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { i18nNamespaces as namespace } from "srs.sharedcomponents/lib/esm/i18n/i18n";
import { IDropDownOption } from "srs.sharedcomponents/lib/esm/models/srs.formDropdownOption.model";
import {
  useAppDispatch,
  useAppSelector,
} from "srs.sharedcomponents/lib/esm/redux/hooks";

import {
  IFacetedItem,
  IFacetedSearchRequest,
} from "srs.sharedcomponents/lib/esm/models/facetedSearch/facetedSearch.model";
import {
  getFacetedOptions,
  getFacetedConfigurations
} from "srs.sharedcomponents/lib/esm/redux/slices/facetedSearchSlice";
import {
  getFacetedProducts,
  updateRequest,
  clearState as clearProducts,
  updateFromMainZone,
  clearProductRefineCatalogs,
  clearState,
  getSortOption
} from "srs.sharedcomponents/lib/esm/redux/slices/facetedProductListSlice";
import * as S from "./styles";
import { FacetedSearchType } from "srs.sharedcomponents/lib/esm/utils/enums";
import { useHistory } from "react-router-dom";
import { useTranslate } from "srs.sharedcomponents/lib/esm/hooks/useTranslate";
import { useTranslation } from "react-i18next";
import FacetedSearchSelectInput from 'srs.sharedcomponents/lib/esm/components/molecules/FacetedSearchSelectInput/FacetedSearchSelectInput'
import { clearAll, clearRefineCatalogs } from "srs.sharedcomponents/lib/esm/redux/slices/productlistSlice";
import LoadingIndicator from "srs.sharedcomponents/lib/esm/components/LoadingIndicator";

interface IFormSchema {
  [key: string]: string;
}
interface IFacetedSearchProps {
  mainZone?: boolean;
  isKeyFinder?: boolean;
  handleReset?: () => void;
  handleShowFacetResult: (isShow: boolean) => void;
  resetInput?: boolean
  setResetInputFields: any
  refineYourSearchInput?: any[] | null
  showFacetResult?: boolean,
  setRefineYourSearchInput: React.Dispatch<React.SetStateAction<any>>;
  setShowRefineSearch: React.Dispatch<React.SetStateAction<boolean>>
  setSearchText: React.Dispatch<React.SetStateAction<any>>
  onSearchButtonClick: (value: boolean) => void;
  disableSearchButton: boolean;
  vinSearchValue: string;
  keyPartNumberSearchValue:string;
}
interface LevelInfo {
  level: number;
  name: string;
}
interface FacetConfiguration {
  levelInfos: LevelInfo[];
  rootTag: string;
}

const FacetedSearch: React.FC<IFacetedSearchProps> = ({
  mainZone,
  isKeyFinder,
  handleReset,
  handleShowFacetResult,
  resetInput,
  setResetInputFields,
  showFacetResult,
  refineYourSearchInput,
  setRefineYourSearchInput,
  setShowRefineSearch,
  setSearchText,
  onSearchButtonClick,
  disableSearchButton,
  vinSearchValue,
  keyPartNumberSearchValue,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [enableSearchButton, setEnableSearchButton] = useState<boolean>(false);
  const { facetItems, facetConfiguration } = useAppSelector(
    (state) => state.facetedSearch
  );
  const [resetInputValues, setResetInputValues] = useState<boolean>(false);
  const { fromMainZone, request: requestFromMainZone } = useAppSelector(
    (state) => state.facetedProductList
  );
  const sortOption = useAppSelector(getSortOption)
  const prevRefineSearchInput = useRef(refineYourSearchInput);
  const [formInputs, setFormInputs] = useState<any>(undefined);
  const { translate } = useTranslate();

useEffect(() => {
  if (vinSearchValue   !='' || keyPartNumberSearchValue !='') {
      setEnableSearchButton(false);
  }
}, [disableSearchButton]); 
  const getOptions = useCallback(
    (level: number) => {
      let filteredItems = facetItems.filter(
        (levelItem) => levelItem.level === level
      );

      const dropdownOptions = filteredItems.map((levelItem) => {
        return { label: levelItem.name, value: levelItem.name };
      });
      return [
        {
          label: t(facetConfiguration?.levelInfos[level].name ?? "", {
            ns: namespace.coreSrsPrimary,
          }),
          value: "",
        },
        ...dropdownOptions,
      ] as IDropDownOption[];
    },
    [facetItems]
  );

  const getDefaultValue = useCallback(
    (levelName: string) => {
      let defaultValue = getValues(levelName as `${number}.${string}`);
      return defaultValue;
    },
    [facetItems, resetInputValues]
  );

  const schema = yup.object().shape({
    facetItems: yup.array().of(
      yup.object().shape({
        rootTag: yup.string(),
        level: yup.number(),
        name: yup.string(),
      })
    ),
  });

  const methods = useForm<IFormSchema[]>({
    defaultValues: formInputs,

    resolver: yupResolver(schema),
    mode: "all",
  });

  const { control, getValues, setValue, reset } = methods;

  const submitForm = useCallback(
    (e) => {
      setResetInputValues(false);
      const request = createFacetRequest(e)
      setEnableSearchButton(request.every(item => item.name !== ''))
      dispatch(updateRequest(request));
      if (mainZone) {
        dispatch(updateFromMainZone(true));
        history.push("/facetedSearch");
      }
      if (!mainZone && request && !isKeyFinder) {
        dispatch(clearProducts());
        dispatch(
          getFacetedProducts({
            refineSearchCriteria: (refineYourSearchInput ?? []).length > 0 ? refineYourSearchInput : null,
            isKeyFinderRefineSearch: true,
            facetItems: request,
            pageNumber: 0,
            pageSize: 9,
            sortBy:sortOption,
          })
        );
      }
      if (facetConfiguration?.type === FacetedSearchType.Dynamic) {
        dispatch(
          getFacetedOptions({ facetItems: request } as IFacetedSearchRequest)
        );
      }
    },
    [facetConfiguration, facetItems, isKeyFinder,sortOption]
  );
  const createFacetRequest = (e: any) => {
    let clear = false;
    const request = facetConfiguration?.levelInfos.map((level) => {
      let name = getValues(level.name as `${number}` | `${number}.${string}`);
      if (clear) {
        name = "";
      } else if (level.name === e.name) {
        name = e.value;
        clear = true;
      }
      return {
        level: level.level,
        name,
        rootTag: facetConfiguration.rootTag,
      };
    }) as IFacetedItem[];
    return request;
  }
  const submitFormOnSelectChange = useCallback(
    (e) => {
      setResetInputValues(false);
      const request = createFacetRequest(e)
      setEnableSearchButton(request.every(item => item.name !== ''))
      dispatch(updateRequest(request));
      if (mainZone) {
        dispatch(updateFromMainZone(true));
        history.push("/facetedSearch");
      }
      if (!mainZone && request && !isKeyFinder) {
        dispatch(clearProducts());
      }
      if (facetConfiguration?.type === FacetedSearchType.Dynamic) {
        dispatch(
          getFacetedOptions({ facetItems: request } as IFacetedSearchRequest)
        );
      }
    },
    [facetConfiguration, facetItems, isKeyFinder]
  );
  const handleSelectChange = (e: { name?: string; value: string }) => {
    onSearchButtonClick(false);
    const modifiedField =
      e.value === "Select Year" ||
        e.value === "Select Make" ||
        e.value === "Select Model" || e.value == ""
        ? { value: "", name: e.name }
        : e;
    submitFormOnSelectChange(modifiedField);
  };

  useEffect(() => {
    if (!fromMainZone) dispatch(clearProducts());
  }, []);

  useEffect(() => {
    if (resetInput) {
      setResetInputValues(true);
      setResetInputFields(false);
    }
  }, [resetInput]);

  useEffect(() => {
    if (!facetConfiguration) {
      dispatch(getFacetedConfigurations());
    }
    //dispatch(getFacetedOptions({} as IFacetedSearchRequest))
  }, [facetConfiguration]);

  useEffect(() => {
    if (facetConfiguration && facetItems.length > 0) {
      let request;
      if (fromMainZone) {
        request = requestFromMainZone;
        if (requestFromMainZone) {
          facetConfiguration?.levelInfos.forEach((config, index) => {
            setValue(
              config.name as `${number}` | `${number}.${string}`,
              Object.values(requestFromMainZone)[index].name as string
            );
          });
        }
        dispatch(updateFromMainZone(false));
      } else {
        request = facetConfiguration?.levelInfos.map((level) => {
          return {
            level: level.level,
            name: null,
            rootTag: facetConfiguration.rootTag,
          } as IFacetedItem;
        });

        dispatch(updateRequest(request));
      }
      if (!mainZone && request) {
        dispatch(clearProducts());
      }
    }
  }, [facetConfiguration,sortOption]);

  const createFieldsAndGetOptions = async () => {
    if (facetConfiguration) {
      setFormInputs(
        facetConfiguration?.levelInfos.reduce<IFormSchema[]>((acc, cur) => {
          return [...acc, { [cur.name]: cur.name }];
        }, [])
      );

      const facetedItemsRequest = facetConfiguration.levelInfos.map((level) => {
        return {
          rootTag: facetConfiguration.rootTag,
          name: null,
          level: level.level,
        } as IFacetedItem;
      });
      dispatch(
        getFacetedOptions({
          facetItems: facetedItemsRequest,
        } as IFacetedSearchRequest)
      );
    }
  };
  useEffect(() => {
    if (!fromMainZone) createFieldsAndGetOptions();
  }, [facetConfiguration]);

  const createRequest = (facetConfiguration: FacetConfiguration) => {
    return facetConfiguration?.levelInfos.map((level) => {
      return {
        level: level.level,
        name: getValues(level.name as `${number}` | `${number}.${string}`),
        rootTag: facetConfiguration.rootTag,
      } as IFacetedItem;
    });
  };

  useEffect(() => {
    const compareArrays = (currentInput: any, prevInput: any) => {
      if (currentInput.length !== prevInput.length) {
        return false;
      }

      const sortedCurrentInput = [...currentInput].sort((a, b) => a - b);
      const sortedPrevInput = [...prevInput].sort((a, b) => a - b);

      return sortedCurrentInput.every((item, index) => item === sortedPrevInput[index]);
    };

    if (prevRefineSearchInput.current === undefined) {
      prevRefineSearchInput.current = refineYourSearchInput;
      return;
    }
    if (!compareArrays(refineYourSearchInput, prevRefineSearchInput.current)) {
      if (showFacetResult && facetConfiguration && (refineYourSearchInput?.length ?? 0) > 0) {
        dispatch(clearState())
        dispatch(clearAll())
        dispatch(clearProducts())
        const request = createRequest(facetConfiguration);
        dispatch(
          getFacetedProducts({
            refineSearchCriteria: (refineYourSearchInput ?? []).length > 0 ? refineYourSearchInput : null,
            isKeyFinderRefineSearch: true,
            facetItems: request,
            pageNumber: 0,
            pageSize: 9,
            sortBy:sortOption
          })
        ).then(() => {
          handleShowFacetResult(true);
        });
        prevRefineSearchInput.current = refineYourSearchInput;
      }
    }
  }, [refineYourSearchInput,sortOption])

  useEffect(()=>{
  customSearch();
},[sortOption])
  const customSearch = () => {
    dispatch(clearProducts())
    onSearchButtonClick(true)
    setShowRefineSearch(false)
    setRefineYourSearchInput([])
    dispatch(clearRefineCatalogs())
    dispatch(clearProductRefineCatalogs())
    dispatch(clearState())
    dispatch(clearAll())
    if (facetConfiguration) {
      const request = createRequest(facetConfiguration);
      let searchText = '';
      request.forEach((item) => {
        searchText += item.name + ' ';
      });
      setSearchText(searchText.trim())
      if (request?.[2]?.name) {
        dispatch(updateRequest(request));
        dispatch(
          getFacetedProducts({
            refineSearchCriteria: null,
            isKeyFinderRefineSearch: true,
            facetItems: request,
            pageNumber: 0,
            pageSize: 9,
            sortBy:sortOption
          })
        ).then(() => {
          handleShowFacetResult(true);
        });
      }
    } else {
      console.warn("facetConfiguration is undefined");
    }
  };

  function handleResetInputs(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    dispatch(clearProducts());
    reset();
    setResetInputValues(true);
    handleReset?.();
    setEnableSearchButton(false);

    const request = facetConfiguration?.levelInfos.map((level) => {
      return {
        level: level.level,
        name: undefined,
        rootTag: facetConfiguration.rootTag,
      } as IFacetedItem;
    });

    dispatch(updateRequest(request));
    if (!mainZone && request) {
      dispatch(clearProducts());
    }

    if (facetConfiguration?.type === FacetedSearchType.Dynamic) {
      dispatch(
        getFacetedOptions({ facetItems: request } as IFacetedSearchRequest)
      );
    }

    createFieldsAndGetOptions();
  }

  return (
    <>
      <LoadingIndicator />
      <div>
        <S.SelectsForm
          onSubmit={submitForm}
          mainImage={mainZone}
          isKeyFinder={isKeyFinder}
        >
          {!isKeyFinder && (
            <S.Text mainImage={mainZone}>
              {" "}
              {translate("FacetedSearchBrowseText")}{" "}
            </S.Text>
          )}
          {facetConfiguration?.levelInfos?.map((level) => (
            <FacetedSearchSelectInput
              key={level.name}
              control={control}
              options={getOptions(level.level)}
              name={level.name}
              onChange={handleSelectChange}
              id={level.name}
              selectedValue={getDefaultValue(level.name)}
              resetValue={resetInputValues}
              isKeyFinder={isKeyFinder}
            />
          ))}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {!mainZone && (
              <button onClick={handleResetInputs} id='keyFinderResetBtn' type="button">
                {translate(`ltl_ClearAllText`)}
              </button>
            )}
            {isKeyFinder && (
              <div className="hideFocus ">
                <S.Button
                  id="makeModelYearSubmitBtn"
                  type="button"
                  onClick={customSearch}
                  disabled={!enableSearchButton}
                  style={{opacity : !enableSearchButton ? 0.3 : 1}}
                >
                  {translate(`lblSearch`)}
                </S.Button>
              </div>
            )}
          </div>
        </S.SelectsForm>
      </div>
    </>
  );
};

export default FacetedSearch;
