/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container } from 'react-bootstrap';
import { useTranslate } from 'srs.sharedcomponents/lib/esm/hooks/useTranslate';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import * as S from './styles';
import { useEffect, useState } from 'react';
import ProductListComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.productList.component';
import FacetedSearchPage from 'srs.sharedcomponents/lib/esm/components/pages/FacetedSearch/FacetedSeachPage';
import FacetedSearch from '../FacetedSearch/FacetedSearch';
import { stringToBoolean } from 'srs.sharedcomponents/lib/esm/utils/helpers';
import RefineYourSearch from 'components/RefineYourSearch/RefineYourSearch';
import {
    useAppSelector,
    useAppDispatch,
} from 'srs.sharedcomponents/lib/esm/redux/hooks';
import {
    selectIsSearching,
    totalItems,
    loading,
    clearRefineCatalogs,
    clearAll,
    updateisMultiSelectFiltersApplied,
    updateMultiSelectFilters,
} from 'srs.sharedcomponents/lib/esm/redux/slices/productlistSlice';
import { IItemShortResponse } from 'srs.sharedcomponents/lib/esm/models/responses/srs.itemShortResponse.model';
import { useLocation, useHistory } from 'react-router-dom';
import {
    mainMenuItems    
} from 'srs.sharedcomponents/lib/esm/redux/slices/welcomeSlice';
import BreadcrumbComponent from 'srs.sharedcomponents/lib/esm/components/srs.breadcrumb.component';
import {
    clearProductRefineCatalogs,
    clearState,
    setSortOption
} from 'srs.sharedcomponents/lib/esm/redux/slices/facetedProductListSlice';
import { bearerToken } from 'srs.sharedcomponents/lib/esm/redux/slices/userSlice';
import PageNotFoundComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.notFoundPage.component';
import { IBreadCrumbResponse } from 'srs.sharedcomponents/lib/esm/models/responses/srs.breadCrumbResponse.model';
import {
    setLoadBreadCrumbLocally,
    setBreadCrumbData,
} from 'srs.sharedcomponents/lib/esm/redux/slices/breadCrumbSlice';
interface IFacetedSearchProps {
    itemListPlaceholder?: () => React.ReactNode;
    isKeyFinder?: boolean;
}
function KeyFinder({
    itemListPlaceholder,
    isKeyFinder,
}: Readonly<IFacetedSearchProps>) {
    const { translate } = useTranslate();
    const [selectedRefineSearchItems, setSelectedRefineSearchItems] = useState<
        any[] | null
    >([]);
    const [lastKeyPartNumberSearched, setLastKeyPartNumberSearched] =
        useState('');
    const [lastVinSearchValueSearched, setLastVinSearchValueSearched] =
        useState('');
    const [keyPartNumberSearchValue, setKeyPartNumberSearchValue] =
        useState('');
    const [vinSearchValue, setVinSearchValue] = useState('');
    const [keyPartValue, setKeyPartValue] = useState('');
    const [vinValue, setVinValue] = useState('');
    const [showItems, setShowItems] = useState(false);
    const history = useHistory();
    const [searchPressed, setSearchPressed] = useState(false);
    const [showKeyPartSearchResultKey, setShowKeyPartSearchResultKey] =
        useState(false);
    const [showVinSearchResultKey, setShowVinSearchResultKey] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [showRefineSearch, setShowRefineSearch] = useState(false);
    const [showFacetResult, setShowFacetResult] = useState<boolean>(false);
    const { search } = useLocation();
    const isSearching = useAppSelector(selectIsSearching);
    const dispatch = useAppDispatch();
    const isLoading: boolean = useAppSelector(loading);
    const [resetInputFields, setResetInputFields] = useState<boolean>(false);
    const searchParams = new URLSearchParams(search);
    let searchParam = decodeURI(searchParams.get('search') ?? '').trim();
    const isKeyPartSearchEnabled = keyPartNumberSearchValue.length >= 3;
    const isVinSearchEnabled = vinSearchValue.length == 17;
    const fullWidthLayout = stringToBoolean(
        process.env.REACT_APP_FULL_WIDTH_LAYOUT
    );
    const total: number = useAppSelector(totalItems);
    const searchItems: IItemShortResponse[] | undefined = useAppSelector(
        (state) => state.productList.productList.items
    );
    const userLoggedIn = !!useAppSelector(bearerToken);
    const makeModelYear: string | null | undefined = useAppSelector((state) => {
        if (showKeyPartSearchResultKey || showVinSearchResultKey    ) {
            return state.productList.productList.makeModelYear;
        }
        return undefined;
    });
    const mainMenu = useAppSelector(mainMenuItems);
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const showBreadCrumb = (name: any) => {
        const breadCrumbObject: IBreadCrumbResponse = {
            child: {
                child: null,
                culture: 1,
                displaySequence: 1,
                id: name,
                name: name,
                parentID: null,
                url: '',
            },
            culture: 1,
            displaySequence: 1,
            id: 1,
            name: 'Key Finder',
            parentID: '',
            url: '',
        };
        dispatch(setLoadBreadCrumbLocally(true));
        dispatch(setBreadCrumbData(breadCrumbObject));
    };
    useEffect(() => {
        if (searchPressed) {
            setShowFacetResult(false);
            setSearchPressed(false);
           
        }
    }, [searchPressed, keyPartNumberSearchValue, vinSearchValue]);

    const clearItems = () => {
        handleReset();
        dispatch(clearAll());
        setResetInputFields(true);
    };

    useEffect(() => {
        dispatch(setSortOption(''))
        dispatch(updateMultiSelectFilters(null));
        dispatch(updateisMultiSelectFiltersApplied(false));
        clearItems();
        return () => {
            clearItems();
        };
    }, []);

    useEffect(() => {
        if (
            (keyPartValue || vinValue) &&
            !searchPressed &&
            showItems &&
            total > 0
        ) {
            setShowRefineSearch(true);
        }
    }, [searchItems, total]);

    const { reset } = useForm({
        defaultValues: {
            make: '',
            model: '',
            year: '',
        },
        mode: 'onBlur',
    });

    const validationSchema = Yup.object().shape({
        search: Yup.string(),
        vin: Yup.string().when('search', {
            is: (search: string | undefined) => !search, // Explicitly type 'search'
            then: Yup.string().min(
                17,
                'VIN must be at least 17 characters long'
            ),
            otherwise: Yup.string(),
        }),
        make: Yup.string(),
        model: Yup.string(),
        year: Yup.string(),
    });

    const handleSearch = async (type: string) => {
        
        setSelectedRefineSearchItems([]);
        try {
            await validationSchema.validate({
                search: keyPartNumberSearchValue,
                vin: vinSearchValue,
            });
            setShowItems(false);

            if (type === 'part-number') {
                setKeyPartValue(keyPartNumberSearchValue);
                setVinValue('');
                setVinSearchValue('');
                setSearchText(keyPartNumberSearchValue);
                setShowVinSearchResultKey(false);
                setShowKeyPartSearchResultKey(true);               
                setLastKeyPartNumberSearched(keyPartNumberSearchValue);
                showBreadCrumb(keyPartNumberSearchValue);               
            } else if (type === 'vin') {
                
                setKeyPartNumberSearchValue('');
                setShowVinSearchResultKey(true);
                setShowKeyPartSearchResultKey(false);
                setKeyPartValue('');
                setVinValue(vinSearchValue);
                setSearchText(vinSearchValue);
                setLastVinSearchValueSearched(vinSearchValue);
                showBreadCrumb(vinSearchValue);                
            }

            const isKeyPartNumberValid =
                keyPartNumberSearchValue &&
                keyPartNumberSearchValue.length >= 3;
            const isVinValid = vinSearchValue && vinSearchValue.length > 16;

            if (isKeyPartNumberValid || isVinValid) {
                setResetInputFields(true);
                setSearchPressed(true);
                dispatch(clearRefineCatalogs());
                dispatch(clearProductRefineCatalogs());
                dispatch(clearAll());
                dispatch(clearState());
                setShowItems(true);
                setShowRefineSearch(false);
            }
        } catch (error: any) {}
    };

  const handleReset = () => {
    setShowItems(false);
    setShowRefineSearch(false);
    setKeyPartValue("");
    setVinValue("");
    setKeyPartNumberSearchValue("");
    setLastKeyPartNumberSearched("");
    setLastVinSearchValueSearched("");
    setVinSearchValue("");
    clearForm();
    showBreadCrumb('');
  };

    const clearForm = () => {
        setKeyPartNumberSearchValue('');
        setLastKeyPartNumberSearched('');
        setLastVinSearchValueSearched('');
        setVinSearchValue('');
        setSearchText('');
        setSearchPressed(false);
        reset({
            make: '',
            model: '',
            year: '',
        });
    };
    const canShowSearchTerm = () => {
        return !isLoading && isSearching && searchParam;
    };

    if (!userLoggedIn) {
        history.push('/login');
    }

    if (userLoggedIn && !mainMenu?.some((item) => item.name === 'Key Finder')) {
        return <PageNotFoundComponent />;
    }

    return (
        <>
            <Container fluid={fullWidthLayout}>
                <BreadcrumbComponent />
            </Container>
            <Container
                fluid={fullWidthLayout}
                className="item__details keyfinder-item-details"
            >
                <S.container>
                    <Col>
                        <S.logo className="pb-3">
                            {translate(`serviceSupport.menu2.title`)}
                        </S.logo>
                        <form className="form-group m-0 text-right w-full">
                            <div
                                style={{ marginBottom: "-5px" }}
                                className="input-group mr-sm-2 hideFocus"
                            >
                                <div
                                    id="key-part-number"
                                    className="input-group mb-2 mr-sm-2 hideFocus"
                                >
                                    <label htmlFor="part-number" className="sr-only">
                                        {translate(`serviceSupport.menu2.item1`)}
                                    </label>
                                    <input
                                        style={{ borderRadius: "4px" }}
                                        type="text"
                                        className="form-control form-control-lg"
                                        id="part-number"
                                        name="part-number"
                                        value={keyPartNumberSearchValue}
                                        onChange={(e) =>
                                            setKeyPartNumberSearchValue(e.target.value)
                                        }
                                        placeholder={translate(`serviceSupport.menu2.item1`)}
                                        onKeyPress={(e) => { if (e.key === ' ') { e.preventDefault(); } }}
                                    />
                                    <div className="header__input-group-append">
                                        <S.Button
                                            id="partNumberSubmitBtn"
                                            type="button"
                                            onClick={() =>
                                                handleSearch('part-number')
                                            }
                                            disabled={!isKeyPartSearchEnabled}
                                            style={{opacity : !isKeyPartSearchEnabled ? 0.3 : 1}}
                                        >
                                            {translate(`lblSearch`)}
                                        </S.Button>
                                    </div>
                                </div>

                                <div id="vin" className="input-group mb-2 mr-sm-2 hideFocus">
                                    <label htmlFor="vin" className="sr-only">
                                        {translate(`serviceSupport.menu2.item2`)}
                                    </label>
                                    <input
                                        style={{ borderRadius: "4px" }}
                                        type="text"
                                        className="form-control form-control-lg"
                                        id="vin"
                                        name="vin"
                                        value={vinSearchValue}
                                        maxLength={17}
                                        onChange={(e) => setVinSearchValue(e.target.value)}
                                        placeholder={translate(`serviceSupport.menu2.item2`)}
                                        onKeyPress={(e) => { if (e.key === ' ') { e.preventDefault(); } }}
                                    />
                                    <div className="header__input-group-append">
                                        <S.Button
                                            disabled={!isVinSearchEnabled}
                                            id="vinsubmit"
                                            type="button"
                                            style={{opacity : !isVinSearchEnabled ? 0.3 : 1}}
                                            onClick={() => handleSearch('vin')}
                                        >
                                            {translate(`lblSearch`)}
                                        </S.Button>
                                    </div>
                                </div>
                            </div>
                            <hr style={{ marginTop: "17px" }} />
                            <S.dropdownContainer className="input-group mr-sm-2 hideFocus">
                                <FacetedSearch
                                    handleShowFacetResult={(isShow: boolean) => {
                                        setKeyPartNumberSearchValue("");
                                        setKeyPartValue("");
                                        setVinSearchValue("");
                                        setLastKeyPartNumberSearched("");
                                        setLastVinSearchValueSearched("");
                                        setVinValue("");
                                        setShowFacetResult(isShow);
                                        setShowItems(true);
                                        setShowRefineSearch(true);
                                        setShowKeyPartSearchResultKey(false);
                                        setShowVinSearchResultKey(false);
                                    }}
                                    showFacetResult={showFacetResult}
                                    isKeyFinder={true}
                                    handleReset={handleReset}
                                    resetInput={resetInputFields}
                                    setResetInputFields={setResetInputFields}
                                    refineYourSearchInput={
                                        selectedRefineSearchItems
                                    }
                                    setRefineYourSearchInput={
                                        setSelectedRefineSearchItems
                                    }
                                    setShowRefineSearch={setShowRefineSearch}
                                    setSearchText={setSearchText}
                                    onSearchButtonClick={setIsSearchClicked}
                                    disableSearchButton={showItems} 
                                    vinSearchValue={vinSearchValue}
                                    keyPartNumberSearchValue={keyPartValue}
                                />
                            </S.dropdownContainer>
                            <hr style={{ margin: '0px' }} />
                        </form>
                        <Container
                            style={{ paddingLeft: '0px', paddingRight: '0px' }}
                        >
                            <S.dropdownContainer className="input-group mr-sm-2 hideFocus">
                                {showRefineSearch && (
                                    <RefineYourSearch
                                        searchText={searchText}
                                        refineYourSearchValues={selectedRefineSearchItems}
                                        setRefineYourSearchValues={setSelectedRefineSearchItems}
                                        isKeyFinder={isKeyFinder}
                                        makeModelYear={makeModelYear}
                                        onSelectionChange={(selected) => {
                                            setShowItems(false);
                                            setSelectedRefineSearchItems([...selected]);
                                            setShowItems(true);
                                        }}
                                    />
                                )}
                            </S.dropdownContainer>
                        </Container>
                    </Col>
                </S.container>
                <Container>
                    {(keyPartValue || vinValue) && !searchPressed && showItems ? (
                        <ProductListComponent
                            keyPartSearchValue={keyPartValue}
                            vinSearchValue={vinValue}
                            isKeyFinder={true}
                            refineYourSearchInput={selectedRefineSearchItems}
                        />
                    ) : (
                        showFacetResult &&
                        showItems && (
                            <FacetedSearchPage
                                isKeyFinder={true}
                                itemListPlaceholder={itemListPlaceholder}
                                refineYourSearchInput={selectedRefineSearchItems}
                                isSearchClicked={isSearchClicked}
                            />
                        )
                    )}
                </Container>
            </Container>
        </>
    );
}

export default KeyFinder;
